import { AIWriteIcon, Typography } from '@ds';
import { RefreshIcon } from '@heroicons/react/outline';
import {
  ChatIcon,
  RssIcon,
  SpeakerphoneIcon,
  ChartBarIcon,
} from '@heroicons/react/solid';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import {
  useCreateNewMediaUpdateMutation,
  useSuggestedActionsMetadataQuery,
} from '@/apollo/generated';
import SuggestedActionCard, {
  DehydratedSuggestedActionCardProps,
} from '@/components/home/components/suggested-action-card';
import { useAlert } from '@/contexts/alert-context';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import routes from '@/utils/routes';

type SuggestedActionIds =
  | 'action-generate-ai-summaries'
  | 'action-prepare-announcement'
  | 'action-post-an-update'
  | 'action-answer-questions'
  | 'action-turn-on-automated-distribution'
  | 'action-view-monthly-board-reports';

const answerQuestionsTitleFn = (
  activeQuestionCount: number | undefined
): string =>
  typeof activeQuestionCount === 'number' && activeQuestionCount > 0
    ? `You have ${activeQuestionCount} active question${
        activeQuestionCount > 1 ? 's' : ''
      }`
    : "You don't have any active questions. Nice work!";

const answerQuestionsDescriptionFn = (
  activeQuestionCount: number | undefined
): string =>
  typeof activeQuestionCount === 'number' && activeQuestionCount > 0
    ? 'Addressing questions from investors is best practice and an effective way of building a long term community.'
    : 'If previously answered questions are appropriate, consider making them public to attract more signups.';

const itsBeenAWhile = (lastPostedDate: string): boolean => {
  const lastPosted = dayjs(lastPostedDate);
  const daysSince = dayjs().diff(lastPosted, 'day');

  return daysSince >= 30;
};

const postAnUpdateTitleFn = (
  lastUpdatePostedDate: string | null | undefined
): string =>
  typeof lastUpdatePostedDate === 'string' &&
  itsBeenAWhile(lastUpdatePostedDate)
    ? "It's been a while since your last update"
    : 'Post an update to your investor hub';

const postAnUpdateDescriptionFn = (
  lastUpdatePostedDate: string | null | undefined
): string =>
  typeof lastUpdatePostedDate === 'string' &&
  itsBeenAWhile(lastUpdatePostedDate)
    ? 'Engaging with your community on a regular basis leads to more signups and better investors.'
    : 'Distribute press releases, videos, and webinars via your investor hub to maximise community engagement.';

const allSuggestedActionsProps: Record<
  SuggestedActionIds,
  DehydratedSuggestedActionCardProps
> = {
  'action-answer-questions': {
    description: answerQuestionsDescriptionFn,
    icon: <ChatIcon className="text-amplify-green-700 h-6 w-6" />,
    title: answerQuestionsTitleFn,
  },
  'action-generate-ai-summaries': {
    description:
      'Include automated announcement summaries to maximise your investor engagement',
    icon: <AIWriteIcon className="h-6 w-6" />,
    title: 'Auto generate summaries with AI',
  },
  'action-post-an-update': {
    description: postAnUpdateDescriptionFn,
    icon: <RssIcon className="text-amplify-green-700 h-6 w-6" />,
    title: postAnUpdateTitleFn,
  },
  'action-prepare-announcement': {
    description:
      'Attract up to 9.6x more investors to your hub by adding a video and summary to your next announcement.',
    icon: <SpeakerphoneIcon className="text-amplify-green-700 h-6 w-6" />,
    title: 'Prepare your next announcement',
  },
  'action-turn-on-automated-distribution': {
    description:
      'Convert 2.2x more investors onto your hub by automatically distributing your announcements.',
    icon: <RefreshIcon className="text-amplify-green-700 h-6 w-6" />,
    title: 'Turn on automated distribution',
  },
  'action-view-monthly-board-reports': {
    description:
      'Track key performance indicators and metrics to assess your overall health and progress.',
    icon: <ChartBarIcon className="text-amplify-green-700 h-6 w-6" />,
    title: 'View my monthly board reports',
  },
};

const SuggestedActions = () => {
  const router = useRouter();
  const {
    push,
    query: { marketListingKey },
  } = router;

  const { formatAndShowError } = useAlert();

  const { data, loading } = useSuggestedActionsMetadataQuery({
    fetchPolicy: 'cache-and-network',
  });
  const suggestedActionsMetadata = data?.suggestedActionsMetadata;
  const isAutomatedDistSwitchedOn =
    !!suggestedActionsMetadata?.isAutomatedDistributionSwitchedOn;
  const AiSummariesOn = !!suggestedActionsMetadata?.aiSummariesEnabled;

  const aiSummaryProps =
    allSuggestedActionsProps['action-generate-ai-summaries'];
  const prepareAnnouncementProps =
    allSuggestedActionsProps['action-prepare-announcement'];
  const postAnUpdateProps = allSuggestedActionsProps['action-post-an-update'];
  const answerQuestionsProps =
    allSuggestedActionsProps['action-answer-questions'];
  const setupAutomatedDistributionProps =
    allSuggestedActionsProps['action-turn-on-automated-distribution'];
  const viewMonthlyBoardReports =
    allSuggestedActionsProps['action-view-monthly-board-reports'];

  const { isPremium } = useCurrentCompanyProfileUser();

  const [createNewMediaUpdate, { loading: createNewMediaUpdateLoading }] =
    useCreateNewMediaUpdateMutation();

  async function onClickCreateNewUpdate() {
    createNewMediaUpdate()
      .then((res) => {
        if (res.data?.createNewMediaUpdate?.id) {
          push({
            pathname: routes.engagement.interactiveMedia.update.edit.href(
              marketListingKey as string,
              res.data.createNewMediaUpdate.id
            ),
            query: { create: true },
          });
        }
      })
      .catch(formatAndShowError);
  }

  // The TypeScript compiler is too dumb to figure this out on it's own I'm afraid
  const postAnUpdateTitleFn = postAnUpdateProps.title as (
    lastUpdatePostedDate: string | null | undefined
  ) => string;
  const postAnUpdateDescriptionFn = postAnUpdateProps.description as (
    lastUpdatePostedDate: string | null | undefined
  ) => string;

  const answerQuestionsTitleFn = answerQuestionsProps.title as (
    activeQuestionCount: number | undefined
  ) => string;
  const answerQuestionsDescriptionFn = answerQuestionsProps.description as (
    activeQuestionCount: number | undefined
  ) => string;

  return (
    <div className="px-4 sm:px-0">
      <Typography variant="text-heading-md">Suggested actions</Typography>
      <div
        className={clsx(
          'mt-4 grid gap-4',
          !isAutomatedDistSwitchedOn &&
            isPremium &&
            'grid-cols-1 sm:grid-cols-2 xl:grid-cols-4',
          isAutomatedDistSwitchedOn == isPremium &&
            'grid-cols-1 sm:grid-cols-2 xl:grid-cols-4',
          isAutomatedDistSwitchedOn &&
            !isPremium &&
            'grid-cols-1 lg:grid-cols-3'
        )}
      >
        {AiSummariesOn ? (
          <SuggestedActionCard
            className="col-span-1"
            description={prepareAnnouncementProps.description as string}
            icon={prepareAnnouncementProps.icon}
            loading={loading || createNewMediaUpdateLoading}
            title={prepareAnnouncementProps.title as string}
            onClick={() =>
              router.push(
                routes.engagement.interactiveMedia.prepareAnnouncement.href(
                  marketListingKey as string
                )
              )
            }
          />
        ) : (
          <SuggestedActionCard
            className="col-span-1"
            description={aiSummaryProps.description as string}
            icon={aiSummaryProps.icon}
            loading={loading || createNewMediaUpdateLoading}
            title={aiSummaryProps.title as string}
            onClick={() =>
              router.push(
                routes.engagement.interactiveMedia.href(
                  marketListingKey as string,
                  'ai-summaries'
                )
              )
            }
          />
        )}

        <SuggestedActionCard
          className="col-span-1"
          description={postAnUpdateDescriptionFn(
            suggestedActionsMetadata?.lastUpdatePostedDate
          )}
          icon={postAnUpdateProps.icon}
          loading={loading || createNewMediaUpdateLoading}
          title={postAnUpdateTitleFn(
            suggestedActionsMetadata?.lastUpdatePostedDate
          )}
          onClick={onClickCreateNewUpdate}
        />
        <SuggestedActionCard
          className="col-span-1"
          description={answerQuestionsDescriptionFn(
            suggestedActionsMetadata?.activeQuestionCount
          )}
          icon={answerQuestionsProps.icon}
          loading={loading || createNewMediaUpdateLoading}
          title={answerQuestionsTitleFn(
            suggestedActionsMetadata?.activeQuestionCount
          )}
          onClick={() =>
            router.push(
              routes.engagement.interactiveMedia.manageQuestions.href(
                marketListingKey as string
              )
            )
          }
        />
        {!isAutomatedDistSwitchedOn && !isPremium && (
          <SuggestedActionCard
            className="col-span-1"
            description={setupAutomatedDistributionProps.description as string}
            icon={setupAutomatedDistributionProps.icon}
            loading={loading || createNewMediaUpdateLoading}
            title={setupAutomatedDistributionProps.title as string}
            onClick={() =>
              router.push(
                routes.engagement.campaigns.settings.automatedDistribution.href(
                  marketListingKey as string
                )
              )
            }
          />
        )}
        {isPremium && (
          <SuggestedActionCard
            className="col-span-1"
            description={viewMonthlyBoardReports.description as string}
            icon={viewMonthlyBoardReports.icon}
            loading={loading || createNewMediaUpdateLoading}
            title={viewMonthlyBoardReports.title as string}
            onClick={() =>
              router.push(
                routes.intelligence.boardReport.href(marketListingKey as string)
              )
            }
          />
        )}
      </div>
    </div>
  );
};

export default SuggestedActions;
